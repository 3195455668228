import React, { FC } from 'react';
import styled from 'styled-components';
import { darken } from 'polished';

import { LIGHTGREY, LIGHTPURPLE } from 'assets/scheduling/kulers';

interface ButtonProps {
  disabled?: boolean;
  fit?: boolean;
  wide?: boolean;
}
interface PurpleButtonProps extends ButtonProps {
  icon?: string;
  children?: React.ReactNode;
  inverted?: boolean;
}

const Btn = styled.button<ButtonProps>`
  background-color: ${(props) => (props.disabled ? LIGHTGREY : LIGHTPURPLE)};
  color: white;
  border-radius: 8px;
  border: 2px solid ${(props) => (props.disabled ? LIGHTGREY : LIGHTPURPLE)};
  font-weight: initial;
  min-width: ${(props) => (props.fit ? 'fit-content' : '160px')};
  width: ${(props) => props.wide && '100%'};
  max-height: 40px;
  white-space: pre;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  svg path {
    fill: white;
  }

  &:hover {
    background-color: ${darken(0.2, LIGHTPURPLE)};
  }

  &:disabled {
    background-color: ${LIGHTGREY};
    cursor: not-allowed;
  }
`;

const InvertedBtn = styled(Btn)`
  background-color: #fff;
  color: ${LIGHTPURPLE};

  svg path {
    fill: ${LIGHTPURPLE};
  }

  &:hover {
    svg path {
      fill: white;
    }
    background-color: ${LIGHTPURPLE};
    color: white;
    border: ${(props) => (props.disabled ? 'none' : `2px solid ${LIGHTPURPLE}`)};
  }
`;

const Icon = styled.img`
  position: relative;
  top: -2px;
  padding-right: 7px;
  height: 20px;
`;

const PurpleButton: FC<PurpleButtonProps> = ({
  icon = null,
  children = <div />,
  inverted = false,
  ...restProps
}) =>
  inverted ? (
    <InvertedBtn {...restProps}>
      {icon && <Icon src={icon} />}
      {children}
    </InvertedBtn>
  ) : (
    <Btn {...restProps}>
      {icon && <Icon src={icon} />}
      {children}
    </Btn>
  );

export default PurpleButton;
