import React, { ReactNode } from 'react';
import styled, { css } from 'styled-components';

interface SegmentProps {
  children: ReactNode;
  raised?: boolean;
  piled?: boolean;
  basic?: boolean;
  inverted?: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key: string]: any;
}

const SegmentWrapper = styled.div<SegmentProps>`
  padding: 1em;
  background: #fff;
  border-radius: 0.28571429rem;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  border: 1px solid #d4d4d5;

  ${(props) =>
    props.raised &&
    css`
      box-shadow: 0 5px 15px rgba(0, 0, 0, 0.15);
    `}

  ${(props) =>
    props.piled &&
    css`
      border-radius: 0;
      background: #f7f7f7;
    `}

  ${(props) =>
    props.basic &&
    css`
      box-shadow: none;
      background: transparent;
      border: none;
    `}

  ${(props) =>
    props.inverted &&
    css`
      background: #333;
      color: #fff;
      border: 1px solid #333;
    `}
`;

// The Segment component itself
const Segment: React.FC<SegmentProps> = ({ children, raised, piled, basic, inverted, ...rest }) => {
  return (
    <SegmentWrapper raised={raised} piled={piled} basic={basic} inverted={inverted} {...rest}>
      {children}
    </SegmentWrapper>
  );
};
export default Segment;
