import React, { FC, useCallback, useEffect } from 'react';
import qs from 'qs';
import PurpleButton from 'components/Buttons/PurpleButton';

import { fetchUserInfo } from 'services/ADP/api/sso';
import { useHistory, useLocation } from 'react-router-dom';

import { ADP_REDIRECT_URI, ADP_PARTNER_CLIENT_ID_CA, ADP_PARTNER_CLIENT_ID_USA } from 'config';
import { SessionService } from 'services';
import {
  ADPLoader,
  BtnContent,
  Heading,
  HeadingContainer,
  Para,
} from 'pages/ADPAuthRedirectPage/ADPIntegration/Auth/Auth.styled';

interface AdpState {
  code: string;
  state: string;
  error: string;
}

const Auth: FC = () => {
  const history = useHistory();
  const location = useLocation();

  const getADPUserinfo = useCallback(
    async (code: string, country: string) => {
      // setPageLoading(true)
      const res = await fetchUserInfo(code, country);
      try {
        if (res && res.user) {
          const { user, jwt } = res;
          SessionService.updateAdpUserInfo(user);
          SessionService.setToken(jwt);
          history.push(`/settings/adp?code=${code}`);
        } else {
          window.j360.toastService.error(res.results ? res.results : null);
          history.push('/');
        }
      } catch (error) {
        console.log(error);
      }
    },
    [history],
  );
  const ssoLogin = (params: AdpState) => {
    let partnerID = '';
    if (params.state === 'CA') {
      partnerID = ADP_PARTNER_CLIENT_ID_CA;
    } else {
      partnerID = ADP_PARTNER_CLIENT_ID_USA;
    }

    const successURI = `https://accounts.adp.com/auth/oauth/v2/authorize?client_id=${partnerID}&response_type=code&scope=openid&redirect_uri=${ADP_REDIRECT_URI}/auth&state=${params.state}`;
    window.location.replace(`${successURI}`);
  };
  useEffect(() => {
    const params = qs.parse(location.search, { ignoreQueryPrefix: true }) as unknown as AdpState;
    if (params.code) {
      getADPUserinfo(params.code, params.state);
    } else if (params.error) {
      history.push('/settings/adp');
    } else {
      ssoLogin(params);
    }
    return () => {};
  }, [getADPUserinfo, history, location.search]);
  return (
    <div>
      <ADPLoader style={{ height: '100vh' }} basic loading>
        <div>
          <HeadingContainer>
            <Heading>Click Here to Authenticate ADP User with J360</Heading>
            <Para />
          </HeadingContainer>
          <BtnContent>
            <PurpleButton>Authenticate ADP</PurpleButton>
          </BtnContent>
        </div>
      </ADPLoader>
    </div>
  );
};
export default Auth;
