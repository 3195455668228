import Segment from 'pages/ADPAuthRedirectPage/ADPIntegration/Auth/Segment';
import styled from 'styled-components';

export const Para = styled.p`
  text-align: center;
  padding: 15px 150px;
  color: #565656;
`;
export const HeadingContainer = styled.div`
  width: 80%;
  text-align: center;
  margin: auto;
  margin-top: 60px;
`;
export const BtnContent = styled.div`
  text-align: center;
`;
export const ADPLoader = styled(Segment)`
  height: 100%;
  ${'' /* width:100%; */}
`;

export const Heading = styled.h2`
  font-size: 30px;
  text-align: center;
`;
